import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { IoLogoGithub, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io5';

interface BetModel {
  name: string;
  tagline: string;
  link?: string;
  imageUrl?: string;
}

const bets: BetModel[] = [{
  name: 'Intentional',
  tagline: 'Know yesterday, build today, design tomorrow'
},{
  name: 'Makefolio',
  tagline: 'The easiest way to build in public'
}, {
  name: 'Coachable',
  tagline: 'Run your coaching practice like a pro'
}, {
  name: 'Stealth Project',
  tagline: 'Tools for Large Language Models'
}, {
  name: 'Codomain',
  tagline: 'Domain-driven design for the rest of us'
}, {
  name: 'Meaningful',
  tagline: 'Act with intention, focus on what matters',
  imageUrl: 'https://meaningfulapp.com/assets/images/image01.png?v=642c444f'
}, {
  name: 'Art of the Spike',
  tagline: 'Putting the "R" back in software "R&D"'
}, {
  name: 'Storyworthy',
  tagline: 'Improve your storytelling',
  imageUrl: 'https://crabl.net/assets/images/storyworthy-iphone.jpg',
  link: 'storyworthy.redefine.software'
}, {
  name: 'Attentive',
  tagline: 'You are what you pay attention to'
}, {
  name: 'Breadboarder',
  tagline: 'Sketch a UI without designing',
  imageUrl: 'https://crabl.net/assets/images/breadboarder-ipad.png'
}, {
  name: 'Layered Text',
  tagline: 'Text editing in higher dimensions',
  imageUrl: 'https://pbs.twimg.com/media/EifhkHKUMAAfk2X?format=jpg&name=medium'
}, {
  name: 'Deep',
  tagline: 'Programmable tools for thought',
  imageUrl: 'https://pbs.twimg.com/media/FK5P0NMXwAIu32A?format=jpg&name=large'
}, {
  name: 'Scribbler',
  tagline: 'Drawing with two hands',
  imageUrl: 'https://crabl.net/assets/images/scribbler-ipad.png'
}, {
  name: 'Rep',
  tagline: 'Social credit building (Startup Weekend \'18)'
}, {
  name: 'Saverr',
  tagline: 'Budgeting made simple (Startup Weekend \'16)'
}, {
  name: 'not crabl',
  tagline: 'Fun with beats, lyrics, and Logic Pro',
  imageUrl: 'https://i1.sndcdn.com/artworks-GGotdmSuerMQkTni-rWunyg-t500x500.jpg'
}, {
  name: 'Headspace HRTF',
  tagline: 'Binaural audio synthesis made easy',
  imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Hrtf_diagram.png'
}, {
  name: 'Astonishing Relay',
  tagline: 'A geocaching game for the pandemic era',
  imageUrl: 'https://crabl.net/assets/images/astonishing-relay-iphone.png'
}, {
  name: 'Roomba\'s Revenge',
  tagline: 'Theme: "What is Home" (Global Game Jam \'19)',
  link: 'https://github.com/crabl/roomba-game',
  imageUrl: 'https://ggj.s3.amazonaws.com/styles/game_sidebar__wide/featured_image/2019/01/208718/roomba-services.png?itok=w5MNsrf4&timestamp=1548631214'
}, {
  name: 'Ghost in the Wire',
  tagline: 'Theme: "Transmission" (Global Game Jam \'18)',
  link: 'https://github.com/trs/ghost-in-the-wire/',
  imageUrl: 'https://ggj.s3.amazonaws.com/styles/game_sidebar__wide/game/featured_image/title-art.png?itok=ZDGWmrp_&timestamp=1517174127'
}, {
  name: 'Shadowbox',
  tagline: 'Simple screenshot utility with shadows'
}]

function Navbar() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '40px', padding: '0px 20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ borderRadius: '40px', marginRight: '16px' }} src="https://crabl.net/assets/images/crabl.jpg" width="40" height="40" />
        <h2>Chris Rabl</h2>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{ marginRight: '24px' }}>Building in Public</span>
        <span style={{ marginRight: '24px' }}>My Story</span>
        <span>Let's Chat</span>
      </div>
    </div>
  )
}

function Bets({ navigate }: any) {

  function getBoxStyle(url?: string) {
    const boxStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid hsla(220, 12%, 22%)',
      borderRadius: '8px',
      backgroundImage: url ? `url(${url})` : `linear-gradient(
        195deg,
        hsl(223deg, 14%, 10%) 0%,
        hsl(225deg, 14%, 11%) 25%,
        hsl(220deg, 14%, 13%) 37%,
        hsl(220deg, 13%, 14%) 43%,
        hsl(222deg, 13%, 15%) 49%,
        hsl(218deg, 13%, 17%) 56%,
        hsl(220deg, 13%, 18%) 64%,
        hsl(222deg, 13%, 19%) 74%,
        hsl(217deg, 12%, 21%) 86%,
        hsl(219deg, 13%, 22%) 100%
      )`,
      backgroundSize: '320px auto',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      width: '200px',
      height: '300px',
      justifyContent: 'flex-end',
      textAlign: 'left',
      lineHeight: 1.4,
      margin: '10px',
      padding: 0
    }

    return boxStyle;
  }


  const boxInnerStyle: React.CSSProperties = {
    display: 'flex', flexDirection: 'column', padding: '80px 20px 20px 20px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', minHeight: '180px',
    background: `linear-gradient(
      0deg,
      hsla(220deg, 12%, 12%, 1) 0%,
      hsla(220deg, 12%, 12%, 1) 9%,
      hsla(220deg, 12%, 12%, 1) 20%,
      hsla(220deg, 12%, 12%, 1) 31%,
      hsla(220deg, 12%, 12%, 1) 42%,
      hsla(220deg, 12%, 12%, 1) 54%,
      hsla(220deg, 12%, 12%, .9) 66%,
      hsla(220deg, 12%, 12%, .6) 78%,
      hsla(220deg, 12%, 12%, .1) 89%,
      hsla(220deg, 12%, 12%, 0) 100%
    )`
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {bets.map(bet => (<div style={getBoxStyle(bet.imageUrl)} onClick={() => navigate(bet.name)}>
        <div style={boxInnerStyle}>
          <strong style={{ marginTop: '20px', fontSize: '1rem', color: 'hsla(220, 12%, 90%)' }}>{bet.name}</strong>
          <span style={{ textAlign: 'left', marginTop: '8px', color: 'hsla(220, 12%, 60%)' }}>{bet.tagline}</span>
        </div>
      </div>))}
    </div>
  );
}

// What's important to define early and iterate on?

// What is your name?
// What is your value proposition? -> Generate a tagline -> Generate a landing page
// Image -> landing page

// Who is going to buy it? -> Customer Segments
// How are you going to connect with them? -> Channels/Relationships

// Assumptions -> Interview questions

// Learnings: what have I learned?
// Actions: what is the next thing that I should do? (based on what?)

function Intentional() {
  return (
    <div style={{padding: '0px 20px'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4>Intentional</h4>
        <span style={{ marginLeft: '16px'}}>
          Know yesterday, build today, design tomorrow
        </span>
      </div>

      <div>
        <h2>Why? Why am I doing this? Why does this matter to me?</h2>
        <ul>
          <li>My life is run by my calendar: if it's not in my calendar, it doesn't exist to me</li>
          <li>The best way to understand someone's values is through their calendar: seeing how they allocate their time is a much better indication of what a person values than what they <em>say</em> they value</li>
          <li>I want to do things with my calendar that no calendar app allows me to do right now: use it as the ultimate planning tool, as well as a tool to understand where I spent my time</li>
          <li>What does the "language of a calendar" look like?</li>
        </ul>
      </div>
    </div>
  )
}

function Makefolio() {
  return (
    <div style={{ padding: '0px 20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4>Makefolio</h4>
        <span style={{ marginLeft: '16px' }}>The easiest way to build in public</span>
      </div>

      <div>
        <h2>Why am I doing this? Why does this matter to me?</h2>
        <ul>
          <li>Want to share my journey, decisions, wins, failures, and story with others (this is the part where Storyworthy comes in: this is mostly a journaling practice)</li>
          <li>"What did you learn today that another person would find useful?"</li>
          <li>Connect with others to make my products better</li>
          <li>Keep myself accountable to building interesting things and iterating on them</li>
          <li>Build a real set of startups and an actual portfolio of small bets</li>
          <li>Manifest the things that I want to build</li>
          <li>Demonstrate progress to myself: the days are long but the years are short</li>
          <li>Connect the dots: sometimes things are interesting to me, but I'm not yet sure why, or where they can be applied</li>
          <li>This is the confluence of many areas that I've been interested in: time and attention management, organization, action, going from zero to one, journaling, understanding my values, getting feedback, building a coaching/mentorship practice</li>
        </ul>

      </div>

      <div>
        <h2>What should this thing be? (public)</h2>
        <span>Idea: use an outliner structure to break down the idea a little (could I use Layered Text to do this?)</span>
        <ul>
          <li>Landing page with a list of all my projects (active and inactive)</li>
          <li>A progress meter of my current MRR vs my goal MRR</li>
          <li>Who wants this? How do I find them?</li>
          <li>What is my business model?</li>
          <li>How to track pivots? (name, mission, tagline, business model, etc)</li>
          <li>How do I connect with them? How do I track those conversations? (could use/extend Coachable for this)</li>
          <li>What do they want? How can I validate the vision? How can I refine the product roadmap?</li>
          <li>How can I get more feedback on my ideas?</li>
          <li>Define the "why", "what", "how" and "who" of each project: "why do I want to build this?" (strategic), "what should it be?" (practical), "how can I build it?" (tactical), "who am I competing against? who should build this?" (social)</li>
        </ul>
      </div>

      <div>
        <h2>How can I test this? (public)</h2>
        <span></span>
        <ul>
          <li>Use it yourself to bootstrap the business (a little meta, but probably doable)</li>
          <li>Use it on behalf of your startup clients</li>
        </ul>
      </div>

      <div>
        <h2>Who is my competition? (private)</h2>
        <ul>
          <li><a href="https://indiepa.ge">Indiepage</a>: limited to a link tree basically</li>
          <li><a href="https://buildfolio.io">Buildfolio</a>: did not even know about this one until I thought of the name as an alternative, but they are very limited (sort of a link tree, but with a little more substance)</li>
          <li><a href="https://linktr.ee">Linktree</a>: pretty generic, doesn't care about the niche of "small bets" builders in public</li>
          <li><a href="https://tiles.bio">Tiles.bio</a>: limited, no real value beyond linktree</li>
          <li><a href="https://overhear.ai/?ref=onepagelove">Overhear.ai</a>: at least on the marketing side, and for "audience building" (pre-launch)</li>
          <li><a href="http://bippd.com">Bipp'd</a>: pivoted to something else pretty quickly</li>
          <li><a href="http://smallbets.co">Small Bets</a>: more of a community play, but still has some elements of this, plus a more collaborative building environment</li>
          <li><a href="https://indiehackers.com">Indie Hackers</a>: definitely a community, but very successful</li>
          <li><a href="https://liveplan.com">LivePlan</a>: sort of like that, but with more of a "build in public" vibe</li>
          <li><a href="https://publiclab.co">Public Lab</a>: not so much a competitor to the SaaS, but a complimentary product that could funnel people in</li>
          <li><a href="https://getmakerlog.com">Makerlog</a>: re-launching on Oct 31, this may be the only serious competition (roadmap: https://github.com/getmakerlog/roadmap)</li>
          <li><a href="https://wip.co">WIP.co</a></li>
        </ul>
        <h2>Problems with competition (private)</h2>
        <ul>
          <li>They're not really adding any more value than Linktree</li>
          <li>They don't actually help you *build* your project</li>
          <li>They are freaking expensive</li>
          <li>They're doing the bare minimum to be viable</li>
        </ul>
      </div>

      <div>
        <h2>How to make money?</h2>
        <ul>
          <li>Patron model: support public builders who ship</li>
          <li>Subscription fee for creators on the platform: problem with this is that it doesn't incentivize *creation* like the patron model does (or does it?)</li>
        </ul>
      </div>

      <div>
        <h2>Inspiration</h2>
        <ul>
          <li><a href="https://publiclab.co/building-in-public/benefits">Public Lab - Building in Public Benefits</a></li>
          <li><a href="https://www.ship30for30.com">Ship 30 for 30</a>: </li>

        </ul>
      </div>
    </div>
  )
}


function App() {
  const [page, setPage] = useState('home');

  const pageStyle: React.CSSProperties = {
    margin: '20px auto',
    width: '100%',
    maxWidth: page === 'home' ? '900px' : '1200px',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width .2s ease-in-out .2s, max-width .2s ease-in-out .2s'
  }

  return (
    <div>
      <div style={pageStyle}>
        <Navbar />

        {page === 'home' && <Bets navigate={(page: string) => setPage(page)} />}
        {page === 'Makefolio' && <Makefolio />}
        {page === 'Intentional' && <Intentional />}
        <div style={{ padding: '40px 20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <span>&copy; 2023 Chris Rabl</span>
          </div>
          <span style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>built in public &#9874; with&nbsp;<a href="https://makefol.io/">makefolio</a></span>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '1.5rem' }}>
            <IoLogoGithub style={{ marginRight: '16px' }} />
            <IoLogoLinkedin style={{ marginRight: '16px' }} />
            <IoLogoTwitter />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
